<template>
  <div class="Applypage">
    <div>活动报名页</div>
  </div>
</template>
<script>
export default {
  setup() {},
};
</script>
<style lang="scss" scoped>
.Applypage {
}
</style>
